import classes from './PlaceHolderListingNew.module.css';
const PlaceHolderListingNew = () => {
	return (
		<>
			<section className={classes.listing}>
				<div className={classes.topSection}></div>
				<div className={classes.bottomSection}>
					<div className={classes.row} />
					<div className={classes.row} />
					<div className={classes.row1} />
				</div>
			</section>
		</>
	);
};
export default PlaceHolderListingNew;
